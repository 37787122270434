import React from "react";
import { Role } from "shared/constants/AppConst";

const ParametrosIndex = React.lazy(() => import("./Parametros"));

export const parametrosPages = [
  {
    path: "/parametros_de_sistema",
    element: <ParametrosIndex />,
    permittedRole: [Role.Admin]
  }
];
