import React from "react";

const Main = React.lazy(() => import("./Cadastro"));

export const assinaturasD4Sign = [
  {
    path: "/assinaturas",
    element: <Main />
  }
];

export default assinaturasD4Sign;
