import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../toolkit/auth.slice";
import reducers from "../reducers";

const persistConfig = (key: string, blacklist?: string[]) => ({
  key,
  storage,
  blacklist
});

const rootReducer = combineReducers({
  auth: persistReducer(
    persistConfig("auth", ["error", "isLoading"]),
    authReducer
  ),

  settings: persistReducer(persistConfig("settings"), reducers.settings),
  common: persistReducer(persistConfig("common"), reducers.common)
});

export type IAsyncThunkConfig = {
  /** return type for `thunkApi.getState` */
  state: IRootState;
  /** type of the `extra` argument for the thunk middleware, which will be passed in as `thunkApi.extra` */
  extra?: any;
  /** type to be passed into `rejectWithValue`'s first argument that will end up on `rejectedAction.payload` */
  rejectValue?: any;
  /** return type of the `serializeError` option callback */
  serializedErrorType?: any;
  /** type to be returned from the `getPendingMeta` option callback & merged into `pendingAction.meta` */
  pendingMeta?: any;
  /** type to be passed into the second argument of `fulfillWithValue` to finally be merged into `fulfilledAction.meta` */
  fulfilledMeta?: any;
  /** type to be passed into the second argument of `rejectWithValue` to finally be merged into `rejectedAction.meta` */
  rejectedMeta?: any;
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;

export default store;
