import React from "react";

const Main = React.lazy(() => import("./Main"));

export const fileExplorerPages = [
  {
    path: "/files",
    element: <Main />
  }
];
