import { Box } from "@mui/material";
import React from "react";
import Logo from "../../../../../assets/icon/logo.png";
import { useThemeContext } from "../../../../utility/AppContextProvider/ThemeContextProvider";

interface AppLogoProps {
  color?: string;
  white?: boolean;
}

const AppLogo: React.FC<AppLogoProps> = ({ color, white }) => {
  const { theme } = useThemeContext();

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        padding: 2.5,
        display: "flex",
        flexDirection: "row",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          height: { xs: 40, sm: 45 }
        }
      }}
      className="app-logo"
    >
      <img src={Logo} width={130} style={{ filter: white ? "" : "none" }}></img>
    </Box>
  );
};

export default AppLogo;
