import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { createUser, login, logout } from "redux/toolkit/auth.slice";
import { IAuthState } from "redux/toolkit/types";
import { IUsuario } from "types/models/app/usuarios";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useSelector<
    IRootState,
    IAuthState
  >((state) => state.auth);
  return {
    isLoading,
    isAuthenticated,
    user
  };
};

export const useAuthMethod = () => {
  const dispatch = useDispatch();

  const signInWithEmailAndPassword = (data: {
    email: string;
    password: string;
  }) => dispatch(login(data));

  const createUserWithEmailAndPassword = async (
    data: Omit<IUsuario, "id" | "perfil" | "indAtivo" | "indVerificado">
  ) => await dispatch(createUser(data));

  const logoutDispatch = () => dispatch(logout());

  return {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    logout: logoutDispatch
  };
};
