import React from "react";

const Links = React.lazy(() => import("./LinksBI"));
const SeletorCategoria = React.lazy(() => import("./SeletorCategoria"));

export const linksBiPages = [
  {
    path: "/links/:id",
    element: <Links />
  },
  {
    path: "/links",
    element: <SeletorCategoria />
  }
];
