import React from "react";
import { Role } from "shared/constants/AppConst";

const Page = React.lazy(() => import("./Page"));

export const pageConfig = [
  {
    path: "/assinatura",
    element: <Page />
  }
];

export default pageConfig;
