import React from "react";

const Contratos = React.lazy(() => import("./Contratos"));

export const contratosPages = [
  {
    path: "/contratos",
    element: <Contratos />
  }
];
