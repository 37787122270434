import React, { ReactNode } from "react";
import AppLoader from "@crema/core/AppLoader";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { IRootState } from "redux/store";
import { IAuthState } from "redux/toolkit/types";

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  const { isLoading } = useSelector<IRootState, IAuthState>(
    (state) => state.auth
  );
  return isLoading ? <AppLoader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired
};
