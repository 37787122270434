import React from "react";

const IframeIndex = React.lazy(() => import("./Iframe"));

export const iframePages = [
  {
    path: "/navegar",
    element: <IframeIndex />
  }
];
