import React from "react";
import { Role } from "shared/constants/AppConst";

const Page = React.lazy(() => import("./Page"));
const SimpleRegister = React.lazy(() => import("./SimpleForm"));

export const pageConfig = [
  {
    path: "/sugestoes",
    element: <Page />,
    permittedRole: [Role.Admin]
  },
  {
    path: "/sugestoes/register",
    element: <SimpleRegister />
  }
];

export default pageConfig;
