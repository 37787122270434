import React from "react";
const Initial = React.lazy(() => import("./Initial"));

export const dashboardPages = [
  {
    path: "/news",
    element: <Initial />,
    permittedRole: []
  }
];
