import React from "react";

const Arquivos = React.lazy(() => import("./Files"));

export const filesPages = [
  {
    path: "/arquivos",
    element: <Arquivos />
  }
];
