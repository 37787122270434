import React from "react";
import { Role } from "shared/constants/AppConst";

const UserProfile = React.lazy(() => import("./Usuarios"));
const PreCadastroUsuarios = React.lazy(() => import("./Precadastros"));

export const usuariosPages = [
  {
    path: "/usuarios",
    element: <UserProfile />,
    permittedRole: [Role.Admin]
  },
  {
    path: "/usuarios-pre-cadastro",
    element: <PreCadastroUsuarios />,
    permittedRole: [Role.Admin]
  }
];
