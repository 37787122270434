import { AuthUser } from "../../types/models/AuthUser";

export const authRole = {
  admin: 1,
  user: 2
};

export enum Role {
  Admin = 1,
  User = 2
}

export const DescriptionsRoles: Record<Role | number, string> = {
  "1": "roles.admin",
  "2": "roles.user"
};

export const PerfisDeAcesso = [
  { name: "Administrador", value: 1 },
  { name: "Usuário", value: 2 }
];

export const defaultUser: AuthUser = {
  uid: "john-alex",
  displayName: "John Alex",
  email: "demo@example.com",
  token: "access-token",
  role: "user",
  photoURL: "/assets/images/avatar/A11.jpg"
};
export const initialUrl = "/news"; // this url will open after login
