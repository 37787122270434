import {
  createAction,
  createAsyncThunk,
  createSlice,
  isAnyOf
} from "@reduxjs/toolkit";
import api from "../../shared/services/index.api";
import { IAsyncThunkConfig } from "../store";
import { fromUnixTime, isAfter } from "date-fns";
import jwt_token, { JwtPayload } from "jwt-decode";
import { JWT_TOKEN_KEY } from "../../shared/constants/ContantsKeys";
import { IAuthState, User } from "./types";
import { ResponseBaseError } from "types/ApiResponses";
import { IUsuario } from "types/models/app/usuarios";
import { IPerfil } from "types/models/app/perfis";

const PREFIX = "app/auth";

const setToken = (idToken: string) => {
  localStorage.setItem(JWT_TOKEN_KEY, idToken);
  api.defaults.headers["Authorization"] = `Bearer ${idToken}`;
};

interface ILoginAsyncThunkAction {
  email: string;
  password: string;
  isAutoRegistrateMethod?: boolean;
}
export const login = createAsyncThunk(
  `${PREFIX}/login`,
  async (action: ILoginAsyncThunkAction, { getState, rejectWithValue }) => {
    const { data, ...response } = await api.post<
      { response: { usuario: IUsuario; id_token: string } } & ResponseBaseError
    >(
      `authenticate/login`,
      {
        username: action.email,
        password: action.password
      },
      {
        validateStatus(status) {
          return status < 500;
        }
      }
    );

    if (response.status === 401) {
      throw rejectWithValue("Credenciais inválidas");
    }

    if (data.hasError) {
      throw rejectWithValue(data.message);
    }

    if (data.response.id_token) {
      setToken(data.response.id_token);
    } else {
      throw rejectWithValue("Token inválido/não existe");
    }

    if (!action.isAutoRegistrateMethod) {
      // Buscar perfil
      const { data: dataPerfil } = await api.get<
        { response: IPerfil } & ResponseBaseError
      >(`/v1/perfis/${data.response.usuario.perfil}`);

      return {
        usuario: {
          ...data.response.usuario,
          perfilDesc: dataPerfil.response.descricao,
          recursos: dataPerfil.response.recursos
        },
        isAutoRegistrateMethod: action.isAutoRegistrateMethod
          ? action.isAutoRegistrateMethod
          : false
      };
    } else {
      return {
        usuario: {
          ...data.response.usuario
        },
        isAutoRegistrateMethod: action.isAutoRegistrateMethod
          ? action.isAutoRegistrateMethod
          : false
      };
    }
  }
);

export const sendMailConfirmation = createAsyncThunk<
  any,
  any,
  IAsyncThunkConfig
>(
  `${PREFIX}/sendMailConfirmation`,
  async (email: string, { getState, rejectWithValue }) => {
    const { data } = await api.post<
      { response: { reponse: Boolean } } & ResponseBaseError
    >(
      `authenticate/cadastrar`,
      {
        username: email
      },
      {
        validateStatus(status) {
          return status < 500;
        }
      }
    );

    if (data.hasError) {
      throw rejectWithValue(data.message);
    }

    return data.response;
  }
);

export const confirmCode = createAsyncThunk<any, any, IAsyncThunkConfig>(
  `${PREFIX}/confirmCode`,
  async (
    action: { email: string; code: number },
    { getState, rejectWithValue }
  ) => {
    const { data } = await api.post<
      { response: { reponse: Boolean } } & ResponseBaseError
    >(
      `authenticate/login`,
      {
        username: action.email,
        password: action.code
      },
      {
        validateStatus(status) {
          return status < 500;
        }
      }
    );

    if (data.hasError) {
      throw rejectWithValue(data.message);
    }

    return data.response;
  }
);

export const recoveryPassword = createAsyncThunk(
  `${PREFIX}/recoveryPassword`,
  async (email: string, { rejectWithValue }) => {
    const { data, status } = await api.get<
      { response: { id_token: string } } & ResponseBaseError
    >(`/v1/usuarios/recuperarSenhaEmail/${email}`, {
      validateStatus(status) {
        return status < 500;
      }
    });

    if (status === 400) {
      throw rejectWithValue("Nenhuma conta existente com o email informado!");
    }

    if (data.hasError) {
      throw rejectWithValue(data.message);
    }

    return data.response;
  }
);

export const createUser = createAsyncThunk(
  `${PREFIX}/createUser`,
  async (action: Partial<IUsuario>, { getState, rejectWithValue }) => {
    const { data, ...response } = await api.post<
      { response: IUsuario } & ResponseBaseError
    >(`v1/usuarios`, action, {
      validateStatus(status) {
        return status < 500;
      }
    });

    if (response.status === 401) {
      throw rejectWithValue("Credenciais inválidas");
    }

    if (data.hasError) {
      throw rejectWithValue(data.message);
    }

    await api.post<{ response: IUsuario } & ResponseBaseError>(
      `v1/usuarios/alterarSenha`,

      { id: data.response.id, password: action.password },
      {
        validateStatus(status) {
          return status < 500;
        }
      }
    );

    return data.response.usuario;
  }
);

export const logout = createAsyncThunk("app/auth/logout", () => {
  localStorage.removeItem(JWT_TOKEN_KEY);
  api.defaults.headers["Authorization"] = undefined;
  return null;
});

export const getValidityToken = createAsyncThunk(
  `${PREFIX}/getValidityToken`,
  (state, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem(JWT_TOKEN_KEY);

    if (token) {
      const { exp } = jwt_token<JwtPayload>(token);

      if (exp !== null && typeof exp === "number") {
        const expirationDate = fromUnixTime(exp);

        if (isAfter(new Date(), expirationDate)) {
          throw rejectWithValue(
            "Sessão expirada, realize a autenticação novamente"
          );
        }
      }
    } else {
      dispatch(logout());
    }
  }
);

export const setUser = createAction<User>(`${PREFIX}/login`);

const initialState: IAuthState = {
  user: {
    photoURL: "/assets/images/placeholder.jpg"
  } as User,
  token: localStorage.getItem(JWT_TOKEN_KEY) ?? "",
  isAuthenticated: false,
  isLoading: false
};

export const authSlice = createSlice({
  name: `${PREFIX}`,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });

    // Login cases
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = { ...action.payload.usuario, photoURL: "" };

      if (action.payload.isAutoRegistrateMethod) {
        state.isAuthenticated = false;
      } else {
        state.isAuthenticated = true;
      }
    });

    builder.addCase(login.rejected, (state) => {
      state.isAuthenticated = false;
      state.user = {} as User;
    });

    builder.addCase(getValidityToken.rejected, (state, action) => {
      if (action.meta.rejectedWithValue) {
        state.user = {} as User;
        state.token = "";
        state.isAuthenticated = false;
        state.isLoading = false;
        state.error = action.error.message;
      }
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.user = {} as User;
      state.token = "";
      state.isAuthenticated = false;
      state.isLoading = false;
    });

    // Matcher cases
    builder.addMatcher(
      isAnyOf(
        getValidityToken.pending,
        login.pending,
        logout.pending,
        sendMailConfirmation.pending,
        confirmCode.pending
      ),
      (state, action) => {
        state.isLoading = true;
        state.error = undefined;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getValidityToken.rejected,
        login.rejected,
        logout.rejected,
        sendMailConfirmation.rejected,
        confirmCode.rejected
      ),
      (state, action) => {
        state.isLoading = false;

        if (!action.meta.rejectedWithValue) {
          state.error = `${
            action.error.code ?? action.error.name
          } - Houve um erro na solicitação: ${action.error.message}`;
        } else {
          state.error = action.payload as string;
        }
      }
    );
    builder.addMatcher(
      isAnyOf(
        getValidityToken.fulfilled,
        login.fulfilled,
        logout.fulfilled,
        sendMailConfirmation.fulfilled,
        confirmCode.fulfilled
      ),
      (state, action) => {
        state.isLoading = false;
        state.error = undefined;
      }
    );
  }
});

export default authSlice.reducer;
