import { Role } from "shared/constants/AppConst";

export const checkPermission = (
  routeRole: any[] | undefined,
  userRole: Role
) => {
  if (!routeRole || !routeRole) {
    return true;
  }

  if (routeRole.length === 0) {
    return !userRole;
  }
  if (userRole && Array.isArray(routeRole)) {
    return routeRole.some((r) => r === userRole);
  }
  return routeRole.includes(userRole);
};
