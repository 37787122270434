import axios, { AxiosError } from "axios";
import EventEmitter from "events";
import { JWT_TOKEN_KEY } from "../constants/ContantsKeys";

const apiEventEmitter = new EventEmitter();

const token = localStorage.getItem(JWT_TOKEN_KEY);

const headers = token ? { Authorization: `Bearer ${token}` } : {};

export const url =
  process.env.NODE_ENV === "development"
    ? "localhost:8080"
    : "app.rsgaranti.org.br";

const api = axios.create({
  baseURL:
    // eslint-disable-next-line no-restricted-globals
    location.protocol + "//" + url + "/api",
  headers,
  validateStatus(status) {
    return status < 500 && status !== 401;
  }
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      // Ocorreu um 401 - Token expirou ou outro motivo
      apiEventEmitter.emit("invalid_token");
    }

    return Promise.reject(error);
  }
);

export { apiEventEmitter };
export default api;
