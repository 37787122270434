import React from "react";
import { Role } from "shared/constants/AppConst";

const Perfis = React.lazy(() => import("./Perfis"));

export const perfisPages = [
  {
    path: "/perfis",
    element: <Perfis />,
    permittedRole: [Role.Admin]
  }
];
