import React from "react";

const Categorias = React.lazy(() => import("./Categorias"));

export const categoriasPages = [
  {
    path: "/categorias",
    element: <Categorias />
  }
];
