import React from "react";
import { Role } from "shared/constants/AppConst";

const Canais = React.lazy(() => import("./Canais"));

export const canaisPages = [
  {
    path: "/canais",
    element: <Canais />
  }
];
