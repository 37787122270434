import { ptBR } from "@mui/material/locale";
import ptMessages from "../locales/pt_BR.json";

const PtLang = {
  messages: {
    ...ptMessages
  },
  muiLocale: ptBR,
  locale: "pt-BR"
};
export default PtLang;
