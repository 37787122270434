import React from "react";

const Calendario = React.lazy(() => import("./Calendario"));

export const calendarioPages = [
  {
    path: "/calendario",
    element: <Calendario />
  }
];
