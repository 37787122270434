import React from "react";

const Signin = React.lazy(() => import("./Signin"));
const Signup = React.lazy(() => import("./Signup"));
const ForgotPassword = React.lazy(() => import("./ForgetPassword"));
const VerifyEmail = React.lazy(() => import("./VerifyEmail"));
const PolicyPrivacy = React.lazy(() => import("./PolicyPrivacy"));

export const authRouteConfig = [
  {
    path: "/signin",
    element: <Signin />
  },
  {
    path: "/signup",
    element: <Signup />
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />
  },
  {
    path: "/verifyEmail",
    element: <VerifyEmail />
  },
  {
    path: "/politica-de-privacidades",
    element: <PolicyPrivacy />
  }
];
