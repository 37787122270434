import { initialUrl } from "shared/constants/AppConst";
import { authRouteConfig } from "./auth";
import { calendarioPages } from "./Calendario";
import { canaisPages } from "./Canais";
import { categoriasPages } from "./Categorias";
import { contratosPages } from "./Contratos";
import assinaturasD4Sign from "./ContratosD4Sign";
import d4SignPages from "./D4Sign";
import { dashboardPages } from "./Dashboard";
import { errorPagesConfigs } from "./errorPages";
import Error403 from "./errorPages/Error403";
import { filesPages } from "./Files";
import { fileExplorerPages } from "./GerenciadorArquivos";
import { iframePages } from "./IframeBrowser";
import { linksBiPages } from "./Links";
import { noticiasPages } from "./Noticias";
import { parametrosPages } from "./Parametros";
import { perfisPages } from "./Perfis";
import policyPrivacyPages from "./PolicyPrivacy";
import { profilePage } from "./profile";
import sugestoesPages from "./Sugestoes";
import { usuariosPages } from "./Usuarios";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...dashboardPages,
    ...profilePage,
    ...filesPages,
    ...linksBiPages,
    ...noticiasPages,
    ...fileExplorerPages,
    ...contratosPages,
    ...assinaturasD4Sign,

    // admins
    ...canaisPages,
    ...usuariosPages,
    ...perfisPages,
    ...categoriasPages,
    ...parametrosPages,
    ...sugestoesPages,
    ...calendarioPages,

    // Iframe
    ...iframePages
  ]
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: [...authRouteConfig, ...policyPrivacyPages]
};

const anonymousStructure = {
  routes: [...errorPagesConfigs, ...d4SignPages]
};

export { anonymousStructure, authorizedStructure, unAuthorizedStructure };
