import AppLayout from "@crema/core/AppLayout";
import AppContextProvider from "@crema/utility/AppContextProvider";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";
import AppStyleProvider from "@crema/utility/AppStyleProvider";
import AppThemeProvider from "@crema/utility/AppThemeProvider";
import AuthRoutes from "@crema/utility/AuthRoutes";
import CssBaseline from "@mui/material/CssBaseline";
import "globals.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import FirebaseAuthProvider from "./@crema/services/auth/firebase/FirebaseAuthProvider";
import store, { persistor } from "./redux/store";
import AuthenticationWrapper from "./shared/wrappers/index.authentication";

const App = () => (
  <AppContextProvider>
    <PersistGate persistor={persistor} loading={null}>
      <Provider store={store}>
        <AppThemeProvider>
          <AppStyleProvider>
            <AppLocaleProvider>
              <BrowserRouter>
                <AuthenticationWrapper>
                  <AuthRoutes>
                    <CssBaseline />
                    <AppLayout />
                  </AuthRoutes>
                </AuthenticationWrapper>
              </BrowserRouter>
            </AppLocaleProvider>
          </AppStyleProvider>
        </AppThemeProvider>
      </Provider>
    </PersistGate>
  </AppContextProvider>
);

export default App;
