import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IRootState } from "redux/store";
import { logout } from "redux/toolkit/auth.slice";
import { apiEventEmitter } from "shared/services/index.api";

// import { Container } from './styles';
interface IAuthenticationWrapper {
  children: React.ReactNode;
}

const Index: React.FC<IAuthenticationWrapper> = ({ children }) => {
  const dispatch = useDispatch();

  const logoutApp = () => {
    dispatch(logout());
  };

  useEffect(() => {
    apiEventEmitter.on("invalid_token", logoutApp);

    return () => {
      apiEventEmitter.removeListener("invalid_token", logoutApp);
    };
  }, []);

  return <>{children}</>;
};

export default Index;
