import React from "react";

const Noticias = React.lazy(() => import("./Noticias"));

export const noticiasPages = [
  {
    path: "/noticias",
    element: <Noticias />
  }
];
